export const dilListesi = [
  {
    text: 'Türkçe',
    value: 'tr',
  },
  {
    text: 'İngilizce',
    value: 'en',
  },
  {
    text: 'Osmanlıca',
    value: 'os',
  },
  {
    text: 'Fransızca',
    value: 'fr',
  },
  {
    text: 'Arapça',
    value: 'ar',
  },
  {
    text: 'Almanca',
    value: 'de',
  },
  {
    text: 'Rusça',
    value: 'ru',
  },
  {
    text: 'Çince',
    value: 'zh',
  },
  {
    text: 'İspanyolca',
    value: 'es',
  },
  {
    text: 'Farsça',
    value: 'fa',
  },
];

export const tipListesi = [
  'kelime', 'tamlama', 'kalıp', 'atasözü', 'deyim', 'kişi', 'eser', 'yeradı', 'olay', 'yapı', 'söz',
];

export const turListesi = [
  'isim', 'fiil', 'sıfat', 'zarf', 'ünlem', 'bağlaç', 'zamir', 'edat', 'mecaz',
];

export const roleList = [
  {
    text: 'Normal Kullanıcı',
    value: 'user',
  },
  {
    text: 'Moderatör',
    value: 'moderater',
  },
  {
    text: 'Admin',
    value: 'admin',
  },
];

export const statusList = [
  {
    text: 'Tümü',
    value: '',
  },
  {
    text: 'Abone',
    value: 'abone',
  },
  {
    text: 'Deneme',
    value: 'deneme',
  },
];

export const altturListesi = [];
export const kokenListesi = [];
export const cinsiyetListesi = [];
export const bicimListesi = [];
export const sinifListesi = [];
export const transkripsiyonListesi = [];
export const fonetikListesi = [];
export const heceliyazimListesi = [];
export const zitanlamListesi = [];
export const esanlamListesi = [];
export const telaffuzListesi = [];
